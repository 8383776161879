@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');

body {
  background-color: #082142;
}

.ant-layout-header {
  background-color: white;
  text-align: center;

  img {
    width: 150px;
  }
}

.links .anticon {
  font-size: 75px;
  margin-bottom: 10px;

  margin-right: 18px;
  margin-top: 7px;
}

.ant-typography {
  font-weight: normal;
}

.learn-more {
  margin-top: 15px;

  .ant-typography {
    color: rgb(123, 192, 56);
  }
}

.page-loading-indicator {
  display: block !important;
  padding: 400px !important;
  color: white !important;
}

@font-family: Raleway, sans-serif;@body-background: #082142;@primary-color: #1e467d;@link-color: #1e467d;